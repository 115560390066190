import './nav.scss';
import {connect} from "react-redux";
import {NavLink} from "react-router-dom";
import config from "../Base/config";
import {loadData} from "../Base/functions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSignOut, faUser} from "@fortawesome/free-solid-svg-icons";

const Nav = (props) => {
    const logout = () => {
        props.loadData({
            route: 'logout',
            callBack: completeLogout,
            response: () => null,
        })
    }

    const completeLogout = () => {
        window.location.reload();
    }

    return (
        <div className="nav">
            <div className="nav__header">
                <NavLink to={'/'}>
                    <h3>{window.location.host.toUpperCase()}</h3>
                </NavLink>
            </div>
            <div className="nav__options">
                <div className="nav__options__elem"></div>
                <div className="nav__options__elem">
                    {props.user && props.user.Username ?
                        <>
                            <FontAwesomeIcon icon={faUser} />
                            <div>{props.user.Username}</div>
                        </>
                        :
                        ''
                    }
                </div>
                {props.user && props.user.User_ID ?
                    <div className="nav__options__elem nav__options__elem--hover" onClick={logout}>
                        <FontAwesomeIcon icon={faSignOut} />
                        <div>Logout</div>
                    </div>
                    :
                    <NavLink to={config.serverBaseURL + '/User'}>
                        <div className="nav__options__elem">{'Login'}</div>
                    </NavLink>
                }
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.user,
})

const mapDispatchToProps = {
    loadData,
}

export default connect(mapStateToProps, mapDispatchToProps)(Nav);