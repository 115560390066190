import {connect} from "react-redux";
import {useEffect, useState} from "react";
import {loadData, saveData, downloadFile} from "./Base/functions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFolder, faFile} from "@fortawesome/free-solid-svg-icons";

const Home = (props) => {
    const [fileList, setFileList] = useState([]);

    useEffect(() => {
        props.loadData({
            route   : 'getFileList',
            callBack: storeFileList,
            response: () => null,
        })
    }, [])

    const storeFileList = (data) => {
        setFileList(data);
    }

    const handleFileDownload = (index, filename) => {
        if (fileList[index].type !== 'dir') {
            downloadFile({
                user: props.user.Username,
                filename,
            })
        }
    }

    const fileIcon = (type) => {
        switch (type) {
            case 'dir':
                return <FontAwesomeIcon icon={faFolder}/>
            default:
                return <FontAwesomeIcon icon={faFile}/>
        }
    }

    const completeDownloadFile = (data) => {

    }

    return (
        <>
            <h1>Home</h1>
        </>
    )
}

const mapStateToProps = (state) => ({
    user: state.user,
})

const mapDispatchToProps = {
    loadData,
    saveData,
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)