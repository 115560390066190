import {createReducer, createAction} from "@reduxjs/toolkit";

export const storeUser = createAction('STORE_USER');

const user = {
    User_ID  : null,
    Username: '',
    hash_name: '',
    PreName : '',
    LastName: '',
    Mail    : '',
    language: navigator.language,
}

const UserReducer = createReducer(user, builder => {
    builder
        .addCase(storeUser, (state, action) => {
            state.User_ID = action.payload.User_ID;
            state.Username = action.payload.Username;
            state.hash_name = action.payload.hash_name;
            state.PreName = action.payload.PreName;
            state.LastName = action.payload.LastName;
            state.Mail = action.payload.Mail;
        })
})

export default UserReducer;