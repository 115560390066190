const Input = (props) => {
    const onChange = (e) => {
        let value = props.type === 'checkbox' ? e.target.checked : e.target.value;
        props.onChange(props.name, value);
    }

    const onKeyDown = (e) => {
        if(props.onKeyDown) {
            props.onKeyDown(e);
        }
    }

    const input_class = props.flex ? 'input input--flex' : props.flex_reverse ? 'input input--flex input input--flex-reverse' : 'input';

    return (
        <div className={input_class}>
            {props.label &&
                <label htmlFor={props.name}>{props.label}</label>
            }
            <input
                className={props.className}
                type={props.type || 'text'}
                name={props.name}
                value={props.value}
                onChange={onChange}
                onKeyDown={onKeyDown}
                placeholder={props.placeholder || props.label || ''}
                required={props.required}
                readOnly={props.readOnly}
                disabled={props.disabled}
                checked={props.checked}
                autoFocus={props.autoFocus}
                style={props.style}
            />
        </div>
    )
}

export default Input;