import {createReducer, createAction} from "@reduxjs/toolkit";

export const setModalActive = createAction('SET_MODAL_ACTIVE');
export const setModalInfo = createAction('SET_MODAL_INFO');
export const resetModalInfo = createAction('RESET_MODAL_INFO');
export const setUploadProgress = createAction('SET_UPLOAD_PROGRESS');

const modal = {
    active  : false,
    type    : '',
    info    : {
        active: false,
        type  : '',
        text  : '',
    },
    progress: null,
    data    : {},
}

const ModalReducer = createReducer(modal, builder => {
    builder
        .addCase(setModalActive, (state, action) => {
            state.active = action.payload.active;
            state.type = action.payload.type;
            if(action.payload.data) {
                state.data = action.payload.data;
            }
        })
        .addCase(setModalInfo, (state, action) => {
            state.info = action.payload;
        })
        .addCase(resetModalInfo, (state) => {
            state.info = modal.info;
        })
        .addCase(setUploadProgress, (state, action) => {
            state.progress = action.payload;
        })
})

export default ModalReducer;