import {connect} from "react-redux";
import {useEffect, useState} from "react";
import {
    downloadFile,
    loadData,
    saveData
} from "../Base/functions";
import File from "./File";
import './files.scss';
import FilePreview from "./FilePreview";
import {setActiveFile, storeFileList, storeSharedData} from "../reducers/FileReducer";
import {setModalActive} from "../reducers/ModalReducer";
import FilesHeader from "./FilesHeader";
import config from "../Base/config";

const FilesContainer = (props) => {
    const [loaded, setLoaded] = useState(false);
    const [xhr, setXhr] = useState(null);

    useEffect(() => {
        loadFileList();
    }, [])

    const loadFileList = () => {
        props.loadData({
            route   : 'getFileList',
            callBack: storeFileList,
            response: () => null,
        })
    }

    const storeFileList = async (data) => {
        await props.storeFileList(data);
        setLoaded(true);
    }

    const handleFileDownload = (path, type, filename) => {
        if (type !== 'dir') {
            downloadFile({
                user: props.user.Username,
                path,
                filename,
            })
        }
    }

    const root = {
        type: 'root',
        name: props.user.Username,
    };

    const handleShareFiles = () => {
        if (props.selectedFiles && props.selectedFiles.length > 0) {
            const fd = new FormData();
            const send_data = props.selectedFiles;
            fd.append('data', JSON.stringify(send_data));

            props.saveData({
                route   : 'shareFile',
                formData: fd,
                callBack: completeShareFile,
                response: () => null,
            })
        }
    }

    const completeShareFile = (data) => {
        props.storeSharedData(data);
        props.setModalActive({
            active: true,
            type  : 'shareLink',
        });
    }

    if (!loaded) return null;

    return (
        <div className="file">
            {!config.isMobile.any() &&
                <FilesHeader handleShareFiles={handleShareFiles}/>
            }
            <div className={'file__container'}>
                <div className={"file__container__fileList"}>
                    <div className="file__container__fileList__list">
                        {/*<File*/}
                        {/*    item={root}*/}
                        {/*/>*/}
                        <div className={config.isMobile.any() ? 'ml--2' : 'ml--20'}>
                            {Object.keys(props.file.tree).map(item => (
                                props.file.tree[item] &&
                                <File
                                    item={props.file.tree[item]}
                                    index={item}
                                    path={props.file.tree[item].name}
                                />
                            ))}
                        </div>
                    </div>
                </div>
                {config.isMobile.any() &&
                    <FilesHeader handleShareFiles={handleShareFiles}/>
                }
                <FilePreview/>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    user         : state.user,
    file         : state.file,
    selectedFiles: state.file.selectedFiles,
})

const mapDispatchToProps = {
    loadData,
    saveData,
    storeFileList,
    setActiveFile,
    storeSharedData,
    setModalActive,
}

export default connect(mapStateToProps, mapDispatchToProps)(FilesContainer)