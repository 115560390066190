import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faChevronLeft,
    faCloudArrowDown,
    faEllipsis,
    faPlusCircle,
    faShareNodes
} from "@fortawesome/free-solid-svg-icons";
import {downloadFile, getDirArray, getNavigateBackData, getNavigateToDirData} from "../Base/functions";
import {navigateBack, navigateToDir, setActiveFile} from "../reducers/FileReducer";
import {setModalActive} from "../reducers/ModalReducer";

const FilesHeader = (props) => {
    const handleShareFiles = props.handleShareFiles;

    let file_path_arr = getDirArray(props.file.path);

    const handlePath = (index) => {
        props.navigateToDir({ index });

        // let dir_data = getNavigateToDirData(props.file, index, file_path_arr);
        // props.setActiveFile(dir_data);
    }

    const back = () => {
        // props.setActiveFile(getNavigateBackData(props.file))
        props.navigateBack();
    }

    const navigateToRoot = () => {
        props.setActiveFile(getNavigateBackData());
    }

    const handleNewFolder = () => {
        props.setModalActive({
            active: true,
            type  : 'newFolder',
        });
    }

    const username = props.shared ? props.sharedData.shared_by.Username : props.user.Username;

    const handleFileDownload = () => {
        if (props.file.selectedFiles.length > 0) {
            for (let i in props.file.selectedFiles) {
                let file = props.file.selectedFiles[i];
                downloadFile({ File_ID: file.File_ID })
            }
        }
    }

    return (
        <div className="file__header">
            <div className={'file__header__pathInfo'}>
                <div className={'file__header__pathInfo__backIcon'} onClick={back}>
                    <FontAwesomeIcon icon={faChevronLeft}/>
                </div>
                {/*{props.path_arr.length > 0 && props.path_arr.map((item, index) => (*/}
                {/*    <div className={'file__header__pathInfo__elem'} key={index}*/}
                {/*         onClick={() => handlePath(index)}><span*/}
                {/*        className={'file__header__pathInfo__elem__slash'}>{index === 0 ? '@' : '/'}</span><span*/}
                {/*        className={'file__header__pathInfo__elem__text'}>{item.name}</span></div>*/}
                {/*))}*/}
            </div>
            <div className="file__header__options">
                {!props.shared &&
                    <>
                        <div
                            className={props.user.User_ID ? "file__header__options__icon" : "file__header__options__icon file__header__options__icon--disabled"}
                            onClick={handleNewFolder}>
                            {props.user.User_ID && props.file.tree && Object.keys(props.file.tree).length === 0 &&
                                <div className="file__header__options__icon__tooltip__newFolder">
                                    Create a new folder to start
                                </div>
                            }
                            <FontAwesomeIcon icon={faPlusCircle}/>
                            <div className="file__header__options__icon__text">
                                new Folder
                            </div>
                        </div>
                        <div
                            className={props.user.User_ID && (props.file.selectedFiles.length > 0) ? "file__header__options__icon" : "file__header__options__icon file__header__options__icon--disabled"}
                            onClick={handleShareFiles}>
                            <FontAwesomeIcon icon={faShareNodes}/>
                            <div className="file__header__options__icon__text">
                                share
                            </div>
                        </div>
                    </>
                }
                <div
                    className={(props.user.User_ID || props.shared) && props.file.selectedFiles.length > 0 ? "file__header__options__icon" : "file__header__options__icon file__header__options__icon--disabled"}
                    onClick={handleFileDownload}>
                    <FontAwesomeIcon icon={faCloudArrowDown}/>
                    <div className="file__header__options__icon__text">
                        {'download'}
                    </div>

                </div>
                <div
                    className={props.user.User_ID ? "file__header__options__icon" : "file__header__options__icon file__header__options__icon--disabled"}>
                    <FontAwesomeIcon icon={faEllipsis}/>
                    <div className="file__header__options__icon__text">
                        more
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    user      : state.user,
    file      : state.file,
    sharedData: state.file.sharedData,
    path_arr  : state.file.active.path_arr,
})

const mapDispatchToProps = {
    setActiveFile,
    setModalActive,
    navigateBack,
    navigateToDir,
}

export default connect(mapStateToProps, mapDispatchToProps)(FilesHeader)