import {connect} from "react-redux";
import {downloadFile, fileIcon, formatByteSize, formatDateTime, saveData} from "../Base/functions";
import {handleFileSelect, setActiveFile, storeSharedData} from "../reducers/FileReducer";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCloudArrowDown,
    faCloudArrowUp, faFile,
    faFolder,
    faShareNodes,
    faTrash,
    faTrashAlt
} from "@fortawesome/free-solid-svg-icons";
import {useEffect, useRef, useState} from "react";
import Input from "../Components/Input";
import {setModalActive} from "../reducers/ModalReducer";
import config from "../Base/config";
import ContextMenuContainer from "../ContextMenu/ContextMenuContainer";

const FileDirPreviewRow = (props) => {
    const [mouseOver, setMouseOver] = useState(false);
    const [contextMenuOpen, setContextMenuOpen] = useState(false);
    const [userHash, setUserHash] = useState(null);

    useEffect(() => {
        if (props.shared && props.file.uploader) {
            const fd = new FormData();
            fd.append('User_ID', props.file.uploader)

            props.saveData({
                route   : 'getUserHash',
                formData: fd,
                callBack: storeUserHash,
                response: () => null,
            })
        }
    }, [])

    const storeUserHash = (data) => {
        if(data && data.hash) {
            setUserHash(data.hash);
        }
    }

    const handleContextMenu = (e) => {
        e.preventDefault();
        setContextMenuOpen(!contextMenuOpen);
    }

    const handleSetFile = () => {
        console.log(props);
        // props.setActiveFile({
        //     type: props.file.type,
        //     data: props.file,
        //     path: props.file.path,
        // });
        props.setActiveFile({
            dir : props.file.type && props.file.type === 'file' ? props.file.Directory_ID : props.file.ID,
            file: props.file.File_ID || null,
            data: props.file || {},
        });
        if (props.file.type === 'file') {
            props.handleFileSelect([props.file]);
        }
    }

    const handleMouseOver = () => {
        setMouseOver(true);
    }

    const handleMouseOut = () => {
        setMouseOver(false);
    }

    const handleFileSelect = (key, value) => {
        let selectedFiles = [...props.selectedFiles];
        if (value) {
            selectedFiles.push(key);
        } else {
            selectedFiles = selectedFiles.filter((item) => item.File_ID !== key.File_ID);
        }
        props.handleFileSelect(selectedFiles);
    }

    const handleShareFile = () => {
        const fd = new FormData();
        const send_data = [props.file]
        fd.append('data', JSON.stringify(send_data));

        props.saveData({
            route   : 'shareFile',
            formData: fd,
            callBack: completeShareFile,
            response: () => null,
        })
    }

    const completeShareFile = (data) => {
        props.storeSharedData(data);
        props.setModalActive({
            active: true,
            type  : 'shareLink',
        });
    }

    const handleDeleteFile = () => {
        props.setModalActive({
            active: true,
            type  : 'confirmDelete',
            data  : props.file,
        })
    }

    const handleFileDownload = () => {
        downloadFile({ File_ID: props.file.File_ID })
    }

    const handleContent = () => {
        const type = props.file.type || 'dir';
        const extension = props.file.extension;
        const path = props.file.file_dir + '/' + props.file.file_dir_name + '.' + props.file.extension;
        const username = props.shared ? userHash : props.user.hash_name;
        return fileIcon(type, extension, path, username);
    }

    const username = props.shared ? props.sharedData.shared_by && props.sharedData.shared_by.Username && props.sharedData.shared_by.Username : props.user.Username;

    if(props.shared && !userHash) return null;

    return (
        <div className="file__container__filePreview__dir__container__elemContainer">
            {contextMenuOpen && !props.shared &&
                <ContextMenuContainer
                    contextMenuOpen={contextMenuOpen}
                    setContextMenuOpen={setContextMenuOpen}
                    item={props.file}
                    path={props.file.path}
                    preview
                />
            }
            <div className="file__container__filePreview__dir" onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}
                 onContextMenu={handleContextMenu}>
                {/*{props.file.type !== 'dir' &&*/}
                {/*    <div className="file__container__filePreview__dir__elem file__container__filePreview__dir__elem__checkbox">*/}
                {/*        <Input*/}
                {/*            name={props.file}*/}
                {/*            type={'checkbox'}*/}
                {/*            onChange={handleFileSelect}*/}
                {/*        />*/}
                {/*    </div>*/}
                {/*}*/}
                <div
                    className="file__container__filePreview__dir__elem file__container__filePreview__dir__elem__checkbox">
                    {props.file.type === 'file' &&
                        <Input
                            name={props.file}
                            type={'checkbox'}
                            onChange={handleFileSelect}
                            checked={props.selectedFiles.some((item) => (item.File_ID === props.file.File_ID))}
                        />
                    }
                </div>
                <div
                    className="file__container__filePreview__dir__elem file__container__filePreview__dir__elem__filename"
                    onClick={handleSetFile}>
                    <div className="file__container__filePreview__dir__elem__filename__icon">
                        {handleContent()}
                        {props.file.shared === 1 &&
                            <div className="file__container__filePreview__dir__elem__filename__icon__isShared">
                                <FontAwesomeIcon icon={faShareNodes}/>
                            </div>
                        }
                    </div>
                    {/*{props.type === 'dir' ? <FontAwesomeIcon icon={faFolder}/> : <FontAwesomeIcon icon={faFile}/>}*/}
                    <div
                        className="file__container__filePreview__dir__elem__filename__text">{props.type === 'dir' ? props.file.Directory_Name : props.file.filename + (props.file.extension ? '.' + props.file.extension : '')}</div>
                </div>
                {!config.isMobile.any() &&
                    <div
                        className={"file__container__filePreview__dir__elem file__container__filePreview__dir__elem__icons"}>
                        {mouseOver && props.file.type !== 'dir' &&
                            <>
                                {!props.shared &&
                                    <FontAwesomeIcon icon={faShareNodes} onClick={handleShareFile}/>
                                }
                                {!['dir', 'root'].includes(props.file.type) &&
                                    <FontAwesomeIcon icon={faCloudArrowDown}
                                                     onClick={handleFileDownload}/>
                                }
                            </>
                        }
                    </div>
                }
                <div
                    className="file__container__filePreview__dir__elem file__container__filePreview__dir__elem__date">{formatDateTime(props.file.created_datetime, config.isMobile.any() ? 'date' : 'datetime')}</div>
                <div
                    className="file__container__filePreview__dir__elem file__container__filePreview__dir__elem__size">{props.type === 'file' && formatByteSize(props.file.size)}</div>
                {!config.isMobile.any() &&
                    <div
                        className="file__container__filePreview__dir__elem file__container__filePreview__dir__elem__del">
                        {mouseOver &&
                            <>
                                {!props.shared &&
                                    <FontAwesomeIcon icon={faTrashAlt} onClick={handleDeleteFile}/>
                                }
                            </>
                        }
                    </div>
                }
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    user         : state.user,
    sharedData   : state.file.sharedData,
    selectedFiles: state.file.selectedFiles,
})

const mapDispatchToProps = {
    setActiveFile,
    saveData,
    storeSharedData,
    setModalActive,
    handleFileSelect,
}

export default connect(mapStateToProps, mapDispatchToProps)(FileDirPreviewRow)