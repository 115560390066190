import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFolderPlus, faPen, faTimes, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {setModalActive} from "../reducers/ModalReducer";
import {saveData} from "../Base/functions";
import file from "../Files/File";

const ContextMenuOption = (props) => {
    const optionIcon = {
        newFolder    : faFolderPlus,
        confirmDelete: faTrashAlt,
        rename       : faPen,
        stopSharing  : faTimes,
    }[props.optionType];

    const stopSharing = () => {
        const file_id = props.item.File_ID;
        const fd = new FormData();
        fd.append('File_ID', file_id);

        if (file_id) {
            props.saveData({
                route: 'stopSharing',
                formData: fd,
                callBack: completeStopSharing,
                response: () => null,
            });
        }
    }

    const completeStopSharing = (data) => {
        console.log(data);
    }

    const optionAction = () => {
        props.handleClickOutside();
        switch (props.optionType) {
            case 'stopSharing': {
                stopSharing();
            }
                break;
            default:
                let data = { ...props.item };
                data['path'] = props.path;
                setModal(data);
        }
    }

    const setModal = (data) => {
        props.setModalActive({
            active: true,
            type  : props.optionType,
            data,
        })
    }

    console.log(props);

    return (
        <div className="contextMenu__elem"
             onClick={optionAction}>
            <div>
                <FontAwesomeIcon icon={optionIcon}/>
            </div>
            <div>
                {props.label}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {
    setModalActive,
    saveData,
}

export default connect(mapStateToProps, mapDispatchToProps)(ContextMenuOption);