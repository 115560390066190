import {createReducer, createAction} from "@reduxjs/toolkit";
import config from "../Base/config";

export const setActiveFile = createAction('SET_ACTIVE_FILE');
export const storeFileList = createAction('STORE_FILE_LIST');
export const storeSharedData = createAction('STORE_SHARED_DATA');
export const handleFileSelect = createAction('HANDLE_FILE_SELECT');
export const setXHR = createAction('SET_XHR');
export const completeAddNewFolder = createAction('COMPLETE_ADD_NEW_FOLDER');
export const completeAddNewFile = createAction('COMPLETE_ADD_NEW_FILE');
export const navigateBack = createAction('NAVIGATE_BACK');
export const navigateToDir = createAction('NAVIGATE_TO_DIR');
export const completeDeleteFile = createAction('COMPLETE_DELETE_FILE');
export const completeDeleteDir = createAction('COMPLETE_DELETE_DIR');
export const handleSkipFiles = createAction('HANDLE_SKIP_FILES');

const file = {
    tree         : {},
    dir_list     : {},
    file_list    : {},
    sharedData   : {
        filename : '',
        shareLink: '',
        shared_by: {},
    },
    selectedFiles: [],
    data         : '',
    path         : '',
    type         : 'root',
    xhr          : null,
    active       : {
        dir     : null,
        file    : null,
        data    : {},
        path_arr: [],
        prev_dir: {},
    }
}

const FileReducer = createReducer(file, builder => {
    builder
        .addCase(setActiveFile, (state, action) => {
            state.active.prev_dir = {
                dir : state.active.dir,
                file: null,
                data: state.active.data,
            }
            const data = action.payload.data ? action.payload.data : action.payload.file ? state.file_list[action.payload.file] : action.payload.dir ? state.dir_list[action.payload.dir] : {};
            state.active.dir = action.payload.dir || null;
            state.active.file = action.payload.file || null;
            state.active.data = data;
            if (action.payload.data && action.payload.dir && !action.payload.file) {
                let dir_list = { ...state.dir_list };
                dir_list[action.payload.dir] = action.payload.data;
                state.dir_list = dir_list;
            }
            if (data) {
                const new_data = {
                    ID  : Number(data.ID),
                    name: data.Directory_Name,
                }
                const entry_exists = state.active.path_arr.some((item) => (item.ID === data.ID));
                let selected_index = null;
                for (let i in state.active.path_arr) {
                    const path_data = { ...state.active.path_arr[i] };
                    if (path_data.ID === data.ID) {
                        selected_index = Number(i);
                    }
                }
                if (!entry_exists) {
                    state.active.path_arr.push(new_data);
                } else {
                    if (selected_index) {
                        let path_arr = state.active.path_arr;
                        path_arr.splice(Number(selected_index) + 1, path_arr.length - (Number(selected_index) + 1));
                        state.active.path_arr = path_arr;
                    }
                }
            }
        })
        .addCase(storeFileList, (state, action) => {
            state.tree = action.payload;
            const x = Object.keys(action.payload).length > 0 ? Number(Object.keys(action.payload)[0]) : null;
            if (x && !state.active.dir) {
                state.active.dir = Number(x);
                state.active.data = action.payload[x];
                state.active.path_arr.push({
                    ID  : Number(x),
                    name: action.payload[x].Directory_Name,
                })
                state.dir_list[x] = action.payload[x];
            }
        })
        .addCase(storeSharedData, (state, action) => {
            console.log(action.payload);
            if (action.payload.hash && action.payload.filenames) {
                let filename = '';
                if (action.payload.filenames.length > 0) {
                    filename = action.payload.filenames.join(', ');
                }
                let shareLink = config.serverBaseURL + '/shared?file=' + action.payload.hash;
                state.sharedData.filename = filename;
                state.sharedData.shareLink = shareLink;
            } else {
                state.tree = action.payload;
                state.active.data = { Files: action.payload };
                // state.sharedData.shared_by = {
                //     User_ID         : action.payload.shared_by,
                //     Username        : action.payload.Username,
                //     PreName         : action.payload.PreName,
                //     LastName        : action.payload.LastName,
                //     shared_timestamp: action.payload.shared_timestamp,
                // };
            }
        })
        .addCase(handleFileSelect, (state, action) => {
            state.selectedFiles = action.payload;
        })
        .addCase(setXHR, (state, action) => {
            state.xhr = action.payload;
        })
        .addCase(completeAddNewFolder, (state, action) => {
            if (state.active.data && state.active.data.Sub_Directories) {
                const Sub_Directories = { ...state.active.data.Sub_Directories };
                state.active.data.Sub_Directories = { ...Sub_Directories, ...action.payload };
            }
        })
        .addCase(completeAddNewFile, (state, action) => {
            if (state.active.data && state.active.data.Files) {
                const Files = { ...state.active.data.Files };
                state.active.data.Files = { ...Files, ...action.payload };
            }
        })
        .addCase(navigateBack, (state) => {
            let path_arr = [...state.active.path_arr];
            path_arr.pop();
            state.active.path_arr = path_arr;
            state.active.data = getTargetDirData(path_arr, state.dir_list);
        })
        .addCase(navigateToDir, (state, action) => {
            let path_arr = state.active.path_arr;
            path_arr.splice(Number(action.payload.index) + 1, path_arr.length - (Number(action.payload.index) + 1));
            state.active.path_arr = path_arr;
            state.active.data = getTargetDirData(path_arr, state.dir_list);
        })
        .addCase(completeDeleteFile, (state, action) => {
            delete state.active.data.Files[action.payload.File_ID];
        })
        .addCase(completeDeleteDir, (state, action) => {
            delete state.active.data.Sub_Directories[action.payload.ID];
        })
        .addCase(handleSkipFiles, (state, action) => {
            const file_keys = Object.keys(state.active.prev_dir.data.Files);
            const index = file_keys.indexOf(state.active.file.toString());
            const skipIndex = action.payload.type === 'next' ? index + 1 : index - 1;
            const skipKey = file_keys[skipIndex];
            const skipData = { ...state.active.prev_dir.data.Files };
            if(skipData[skipKey]) {
                state.active.data = skipData[skipKey];
                state.active.file = skipKey;
            }
        })

})

const getTargetDirData = (path_arr, dir_list) => {
    let last_index = path_arr.length - 1;
    if (last_index >= 0) {
        let last_id = path_arr[last_index].ID;
        return dir_list[last_id];
    } else {
        return {};
    }
}

export default FileReducer;