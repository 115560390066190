import {connect} from "react-redux";
import {Outlet} from "react-router-dom";
import {useEffect, useState} from "react";

import {loadData} from "./Base/functions";
import {storeUser} from "./reducers/UserReducer";
import Nav from "./Nav/Nav";
import ModalContainer from "./Modal/ModalContainer";
import Forbidden from "./Base/Forbidden";

const AppContent = (props) => {
    useEffect(() => {
        initial_load();
    }, [])

    const initial_load = () => {
        props.loadData({
            route   : 'loadUserData',
            callBack: props.storeUser,
            response: () => null,
        })
    }

    return (
        <div className={'main'} id={'main'}>
            <Nav/>
            <div className="content">
                <Outlet/>
            </div>
            {props.modal.active &&
                <ModalContainer/>
            }
        </div>
    )
}

const mapStateToProps = (state) => ({
    modal: state.modal,
})

const mapDispatchToProps = {
    loadData,
    storeUser,
}

export default connect(mapStateToProps, mapDispatchToProps)(AppContent);