import {useEffect} from "react";

const Forbidden = () => {
    useEffect(() => {
        document.title = '403 Forbidden';
    },[])

    return (
        <div style={{padding: '20px'}}>
            <h1>Forbidden</h1>
            <p>You don't have permission to access this resource.Server unable to read htaccess file, denying access to be safe</p>
        </div>
    )
}

export default Forbidden;