import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFolderPlus, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {useEffect, useRef} from "react";
import {setModalActive} from "../reducers/ModalReducer";
import './context_menu.scss';
import ContextMenuOption from "./ContextMenuOption";

const ContextMenuContainer = (props) => {
    const setContextMenuOpen = props.setContextMenuOpen;

    const myRef = useRef();

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        }
    }, [])

    const handleClickOutside = (event) => {
        if ((myRef.current && event && !myRef.current.contains(event.target)) || !event) {
            setContextMenuOpen(false);
        }
    }

    const optionTypes = [
        {
            optionType: 'newFolder',
            fileTypes : ['dir'],
            label     : 'New folder',
        },
        {
            optionType: 'confirmDelete',
            fileTypes : ['dir', 'file'],
            label     : 'Delete',
        },
        {
            optionType: 'rename',
            fileTypes : ['dir', 'file'],
            label     : 'Rename',
        }
    ];
    if (Number(props.item.shared) === 1) {
        optionTypes.push({
            optionType: 'stopSharing',
            fileTypes : ['dir', 'file'],
            label     : 'Stop sharing',
        })
    }

    return (
        <div ref={myRef} className={props.preview ? "contextMenu contextMenu--preview" : "contextMenu"}>
            {optionTypes.map((type, index) => (
                type.fileTypes.includes(props.item.type) &&
                <ContextMenuOption
                    optionType={type.optionType}
                    item={props.item}
                    path={props.path}
                    handleClickOutside={handleClickOutside}
                    label={type.label}
                />
            ))}
        </div>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {
    setModalActive,
}

export default connect(mapStateToProps, mapDispatchToProps)(ContextMenuContainer);