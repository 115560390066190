import {connect} from "react-redux";
import Input from "../Components/Input";
import Form from "../Components/Form";
import {useState} from "react";
import {saveData} from "../Base/functions";

import {storeUser} from "../reducers/UserReducer";
import config from "../Base/config";

import {useNavigate} from "react-router-dom";

const Login = (props) => {
    const [userData, setUserData] = useState({
        mail_name    : '',
        password: '',
    })

    const navigate = useNavigate();

    const onSubmit = () => {
        let fd = new FormData();
        fd.append('data', JSON.stringify(userData));

        props.saveData({
            route   : 'login',
            formData: fd,
            callBack: loginUser,
            response: () => window.location.href = config.serverBaseURL,
        })
    }

    const loginUser = async (data) => {
        await props.storeUser(data);
        navigate('/');
    }

    const onChange = (key, value) => {
        let newData = {...userData};
        newData[key] = value;
        setUserData(newData);
    }

    return (
        <>
            <h2>Login</h2>
            <Form onSubmit={onSubmit} submitValue={'Login'}>
                <Input
                    value={userData.mail_name}
                    label={'E-Mail/Username:'}
                    name={'mail_name'}
                    onChange={onChange}
                    required
                    type={'mail'}
                />
                <Input
                    value={userData.password}
                    label={'Passwort:'}
                    name={'password'}
                    onChange={onChange}
                    type={'password'}
                    required
                />
            </Form>
        </>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {
    saveData,
    storeUser,
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)