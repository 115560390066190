import {connect} from "react-redux";
import Form from "../Components/Form";
import Input from "../Components/Input";
import {useState} from "react";
import {saveData} from "../Base/functions";

import {storeUser} from "../reducers/UserReducer";

const Register = (props) => {
    const [userData, setUserData] = useState({
        preName       : '',
        lastName      : '',
        mail          : '',
        username      : '',
        password      : '',
        repeatPassword: '',
        acceptPrivacy : false,
    })

    const onChange = (key, value) => {
        let newData = {...userData};
        newData[key] = value;
        setUserData(newData);
    }

    const onSubmit = () => {
        if (userData.password !== userData.repeatPassword) {
            alert('Passwörter stimmen nicht überein!');
        } else {
            let fd = new FormData();
            fd.append('data', JSON.stringify(userData));

            props.saveData({
                route   : 'register',
                formData: fd,
                callBack: completeRegister,
                response: () => null,
            })
        }
    }

    const completeRegister = (data) => {
        // props.storeUser({
        //     userID  : data.userID,
        //     username: data.username,
        //     preName : data.preName,
        //     lastName: data.lastName,
        // })
        props.toggleType(0);
    }

    return (
        <>
            <h2>Register</h2>
            <Form onSubmit={onSubmit} submitValue={'Register'}>
                <Input
                    value={userData.preName}
                    label={'Vorname:'}
                    name={'preName'}
                    onChange={onChange}
                    required
                />
                <Input
                    value={userData.lastName}
                    label={'Nachname:'}
                    name={'lastName'}
                    onChange={onChange}
                    required
                />
                <Input
                    value={userData.mail}
                    label={'E-Mail:'}
                    name={'mail'}
                    onChange={onChange}
                    required
                    type={'mail'}
                />
                <Input
                    value={userData.username}
                    label={'Username:'}
                    name={'username'}
                    onChange={onChange}
                    required
                />
                <Input
                    value={userData.password}
                    label={'Passwort:'}
                    name={'password'}
                    onChange={onChange}
                    type={'password'}
                    required
                />
                <Input
                    value={userData.repeatPassword}
                    label={'Passwort wiederholen:'}
                    name={'repeatPassword'}
                    onChange={onChange}
                    type={'password'}
                    required
                />
                {/*<Input*/}
                {/*    checked={userData.acceptPrivacy}*/}
                {/*    label={'Ich stimme den Datenschutzrichtlinien zu:'}*/}
                {/*    name={'acceptPrivacy'}*/}
                {/*    onChange={onChange}*/}
                {/*    type={'checkbox'}*/}
                {/*    required*/}
                {/*    flex_reverse*/}
                {/*/>*/}
            </Form>
        </>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {
    saveData,
    storeUser,
}

export default connect(mapStateToProps, mapDispatchToProps)(Register)