import AppContent from "./AppContent";
import Home from "./Home";
import {createBrowserRouter} from "react-router-dom";
import UserContainer from "./User/UserContainer";
import FilesContainer from "./Files/FilesContainer";
import SharedFilesContainer from "./Shared/SharedFilesContainer";
import Forbidden from "./Base/Forbidden";

export const router = createBrowserRouter([
    {
        path    : '/',
        element : <AppContent/>,
        children: [
            {path: '/', element: <FilesContainer/>},
            {path: '/user', element: <UserContainer/>},
            {path: '/home', element: <Home/>},
            {path: '/files', element: <FilesContainer/>},
            {path: '/shared', element: <SharedFilesContainer />},
            {path: '/cloudFiles', element: <Forbidden />}
        ],
    }
])