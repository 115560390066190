import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faFile,
    faFileLines,
    faFolder,
    faChevronRight,
    faFolderPlus,
    faUser,
    faTrashAlt
} from "@fortawesome/free-solid-svg-icons";
import {useEffect, useRef, useState} from "react";
import config from "../Base/config";
import {setActiveFile} from "../reducers/FileReducer";
import {fileIcon, formatByteSize} from "../Base/functions";
import {setModalActive} from "../reducers/ModalReducer";
import ContextMenuContainer from "../ContextMenu/ContextMenuContainer";

const File = (props) => {
    const [folderOpen, setFolderOpen] = useState(null);
    const [contextMenuOpen, setContextMenuOpen] = useState(false);

    useEffect(() => {
        if (props.path_arr) {
            let openFolder = props.path_arr && props.path_arr.some((item) => (item.ID === props.item.ID));
            setFolderOpen(openFolder);
        }
    }, [props.path_arr])

    const handleContextMenu = (e) => {
        e.preventDefault();
        setContextMenuOpen(!contextMenuOpen);
        setFolderOpen(true);
    }

    const toggleFolder = () => {
        if (props.item.type !== 'root') {
            setFolderOpen(!folderOpen);
        }
    }

    const handleSetFile = () => {
        // props.setActiveFile({
        //     type: props.item.type,
        //     data: props.item,
        //     path: props.path
        // });
        props.setActiveFile({
            dir : props.item.ID,
            data: props.item,
        });
        // if (props.item.type === 'dir') {
        //     if (props.item.dir && Object.keys(props.item.dir).length > 0) {
        //         setFolderOpen(!folderOpen);
        //     }
        // }
    }

    if (props.item.type === 'file') return null; //Files won't be displayed, only directories and root!

    return (
        <div className={'file'}>
            {contextMenuOpen &&
                <ContextMenuContainer
                    contextMenuOpen={contextMenuOpen}
                    setContextMenuOpen={setContextMenuOpen}
                    item={props.item}
                    path={props.path}
                />
            }
            <div className={'file__elem'} onClick={handleSetFile}
                 onContextMenu={handleContextMenu}>
                <div className={'file__text'}>
                    {/*{props.item.type === 'root' ?*/}
                    {/*    <div*/}
                    {/*        className={folderOpen || props.item.type === 'root' ? 'file__chevron file__chevron--open' : 'file__chevron'}>*/}
                    {/*        <FontAwesomeIcon icon={faChevronRight} onClick={toggleFolder}/>*/}
                    {/*    </div>*/}
                    {/*    :*/}
                    {/*    (props.item.type === 'dir' && props.item.dir && Object.keys(props.item.dir).length > 0) ?*/}
                    {/*        <div*/}
                    {/*            className={folderOpen || props.item.type === 'root' ? 'file__chevron file__chevron--open' : 'file__chevron'}>*/}
                    {/*            <FontAwesomeIcon icon={faChevronRight} onClick={toggleFolder}/>*/}
                    {/*        </div>*/}
                    {/*        :*/}
                    {/*        null*/}
                    {/*}*/}
                    <div
                        className={folderOpen || props.item.type === 'root' ? 'file__chevron file__chevron--open' : 'file__chevron'}>
                        <FontAwesomeIcon icon={faChevronRight} onClick={toggleFolder}/>
                    </div>
                    <div className="file__text__name">
                        {/*{fileIcon(props.item.type, props.item.extension, props.path, props.user.Username, true)}*/}
                        <FontAwesomeIcon icon={faFolder} onClick={toggleFolder}/>
                        <div>{props.item.Directory_Name}</div>
                    </div>
                </div>
                {/*{!['dir', 'root'].includes(props.item.type) &&*/}
                {/*    <div>*/}
                {/*        {formatByteSize(props.item.size)}*/}
                {/*    </div>*/}
                {/*}*/}
            </div>
            {props.item.Sub_Directories && Object.keys(props.item.Sub_Directories).length > 0 && folderOpen &&
                <div className="file__sub">
                    {Object.keys(props.item.Sub_Directories).map(elem => (
                        <File
                            key={elem}
                            handleFileDownload={props.handleFileDownload}
                            item={props.item.Sub_Directories[elem]}
                            index={elem}
                            setActiveFile={props.setActiveFile}
                            user={props.user}
                            setModalActive={props.setModalActive}
                            path_arr={props.path_arr}
                        />
                    ))}
                </div>
            }
        </div>
    )
}

const mapStateToProps = (state) => ({
    user    : state.user,
    path_arr: state.file.active.path_arr,
})

const mapDispatchToProps = {
    setActiveFile,
    setModalActive,
}

export default connect(mapStateToProps, mapDispatchToProps)(File)