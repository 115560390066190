import config from "./config";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faFile,
    faFileImage,
    faFileLines,
    faFileVideo,
    faFolder,
    faMusic,
    faUser
} from "@fortawesome/free-solid-svg-icons";

const LOAD_DATA = 'LOAD_DATA';
const SAVE_DATA = 'SAVE_DATA';

export function loadData(data) {
    const title = data.title || 'Daten werden geladen';
    const errorTitle = data.errorTitle || 'Daten konnten nicht geladen werden!';

    fetch(config.baseURL + 'backend/' + data.route,
        {
            method     : 'GET',
            credentials: 'same-origin',
        }
    ).then(res => res.json()
    ).then(res => {
        if (res !== '' && res !== {} && res.code !== 200) {
            data.callBack({
                ...res.data,
            });
            if (data.response) {
                data.response({
                    active: false,
                    hidden: false,
                })
            }
        } else {
            if (data.response && res.code === 200) {
                data.response({
                    active   : true,
                    hidden   : false,
                    type     : 'error',
                    title    : errorTitle,
                    text     : 'Error-Code: ' + res.code + ' - ' + res.error_msg,
                    errorData: res.data || null,
                })
            }
        }
    }).catch(error => {
        if (data.response) {
            data.response({
                active: true,
                type  : 'error',
                title : errorTitle,
                text  : error + ''
            })
        }
    });
    let hidden = data.hidden ? data.hidden : false;
    return { type: LOAD_DATA, title, hidden }
}

export function saveData(data) {
    fetch(config.baseURL + 'backend/' + data.route,
        {
            method     : 'POST',
            credentials: 'same-origin',
            body       : data.formData,
        }
    ).then(res => res.json()
    ).then(res => {
        if (res !== '' && res !== {} && res.code !== 200) {
            data.callBack({
                ...res.data,
            });
            if (data.response) {
                data.response({
                    active: false
                })
            }
        } else {
            if (data.response && res.code === 200) {
                data.response({
                    active: true,
                    type  : 'error',
                    title : data.errorTitle,
                    // text  : Translations[res.error_msg][data.language],
                    errorData: res.data || null,
                })
            }
        }
    }).catch(error => {
        if (data.response) {
            data.response({
                active: true,
                type  : 'error',
                title : data.errorTitle,
                text  : error + ''
            })
        }
    });
    let title = data.title;
    let hidden = data.hidden ? data.hidden : false;
    return { type: SAVE_DATA, title, hidden }
}

export function downloadFile(data) {
    let fd = new FormData();
    fd.append('File_ID', JSON.stringify(data.File_ID));
    // fd.append('path', data.path);
    // fd.append('filename', data.filename);

    fetch(config.baseURL + 'backend/downloadFile',
        {
            method     : 'POST',
            credentials: 'same-origin',
            body       : fd,
        }
    ).then(res => {
        console.log(res);
        if (res.ok) {
            const contentDisposition = res.headers.get('Content-Disposition');
            console.log(res.headers);
            const matches = /filename="([^"]+)"/.exec(contentDisposition);
            if(matches?.[1]) {
                const filename = matches?.[1];
                return res.blob().then(blob => ({ blob, filename }));
            }else{
                throw new Error('Download failed!');
            }
        }
        throw new Error('Network Error!');
    }).then(({ blob, filename }) => {
        console.log(blob);
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();

        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(url);
    }).catch(error => {
        console.error('Download-Error', error);
    })
}

export const fileUpload = (data) => {
    const url = config.baseURL + 'backend/' + data.route;
    const xhr = new XMLHttpRequest();

    if(data.xhr) {
        data.xhr(xhr);
    }

    xhr.open('POST', url, true);
    xhr.withCredentials = true;
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

    window.onbeforeunload = function() {
        return "Your upload will be canceled if you exit this website. Do you want to continue?";
    };

    xhr.onloadend = function() {
        window.onbeforeunload = null;
    };

    xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
            const progress = Math.round((event.loaded / event.total) * 100);
            if (data.progress) {
                data.progress(progress);
            }
        }
    };

    xhr.onload = function() {
        if (this.status === 200) {
            try {
                const response = JSON.parse(this.response);
                if (data.callBack) {
                    data.callBack(response.data);
                }
            } catch (error) {
                if (data.response) {
                    data.response({
                        active: true,
                        type: 'error',
                        title: data.errorTitle,
                        text: 'Fehler beim Parsen der Antwort: ' + error
                    });
                }
            }
        } else {
            if (data.response) {
                data.response({
                    active: true,
                    type: 'error',
                    title: data.errorTitle,
                    text: 'Server-Fehler: ' + this.statusText
                });
            }
        }
    };

    xhr.onerror = function() {
        if (data.response) {
            data.response({
                active: true,
                type: 'error',
                title: data.errorTitle,
                text: 'Netzwerkfehler oder Anfrage abgelehnt'
            });
        }
    };

    xhr.send(data.formData);
};

// export const formatDateTime = (datetime, fullMonths) => {
//     let months = ['', 'Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'];
//     let date = datetime.split(' ')[0].split('-').reverse().join('.');
//     if (fullMonths) {
//         let fullmonth = months[Number(date.split('.')[1])];
//         date = date.split('.')[0] + '. ' + fullmonth + ' ' + date.split('.')[2];
//     }
//     let time = datetime.split(' ')[1].slice(0, 5);
//     return date + ', ' + time;
// }

export const sortObjectToReverseArray = (obj) => {
    return Object.keys(obj).map((item) => (obj[item])).reverse();
}

const sleep = async (ms) => {
    await new Promise(resolve => setTimeout(resolve, ms));
}

export const formatByteSize = (size) => {
    const len = size.toString().length;
    const exp = len - (len % 3);
    const type = exp / 3;
    let file_size_unit = 'B';
    switch (type) {
        case 0:
            file_size_unit = 'B';
            break;
        case 1:
            file_size_unit = 'KB';
            break;
        case 2:
            file_size_unit = 'MB';
            break;
        case 3:
            file_size_unit = 'GB';
            break;
        case 4:
            file_size_unit = 'TB';
            break;
        default:
            file_size_unit = 'B';
    }
    return Math.round(Number(size / (10 ** exp)) * 100) / 100 + ' ' + file_size_unit;
}

export const fileIcon = (type, extension, path, username, icon) => {
    if (type === 'dir') {
        return <FontAwesomeIcon icon={faFolder}/>
    } else if (type === 'root') {
        return <FontAwesomeIcon icon={faUser}/>
    } else {
        switch (extension.toLowerCase()) {
            case 'txt':
                return <FontAwesomeIcon icon={faFileLines}/>
            case 'jpg':
            case 'jpeg':
            case 'gif':
            case 'png':
            case 'tiff':
            case 'bmp':
                if (icon) {
                    return <img src={config.baseURL + 'cloudFiles/' + username + '/' + path} height={20} alt={''}/>
                } else {
                    return <img src={config.baseURL + 'cloudFiles/' + username + '/' + path} alt={''}/>
                }
            case '3gp':
            case 'm4v':
            case 'mkv':
            case 'webm':
            case 'ogg':
            case 'mov':
            case 'avi':
            case 'wmv':
            case 'mp4':
            case 'mpeg4':
                return icon ? <FontAwesomeIcon icon={faFileVideo}/> :
                    <video src={config.baseURL + 'cloudFiles/' + username + '/' + path} controls/>
            case 'wav':
            case 'mp3': {
                if (icon) {
                    return <FontAwesomeIcon icon={faMusic}/>
                } else {
                    return <audio src={config.baseURL + 'cloudFiles/' + username + '/' + path} controls/>
                }
            }
            default:
                return <FontAwesomeIcon icon={faFile}/>
        }
    }
}

export const getNavigateBackData = (file, shared) => { //Um zum root-Verzeichnis zu navigieren Funktion ohne Parameter aufrufen!
    if (!file || shared) {
        return {
            type: 'root',
            path: '',
        }
    }
    const path = file.path.replace('/' + file.data.name, '');

    if (path === file.data.name || path === '') {
        return {
            type: 'root',
            data: file.list,
            path: '',
        }
    }

    let data = {};
    let tmp_data = {};
    if (path.includes('/')) {
        let pathArr = path.split('/');
        for (let i = 0; i <= pathArr.length; i++) {
            let curr_path = pathArr[i];
            if (Number(i) === 0) {
                if (file.list[curr_path]) {
                    tmp_data = file.list[curr_path];
                }
            } else if (Number(i) === pathArr.length - 1) {
                if (tmp_data['dir'][curr_path]) {
                    data = tmp_data['dir'][curr_path];
                }
            } else {
                if (tmp_data['dir'][curr_path]) {
                    tmp_data = tmp_data['dir'][curr_path];
                }
            }
        }
    } else {
        if (file.list[path]) {
            data = file.list[path];
        }
    }
    return {
        type: data.type,
        data,
        path,
    };
}

export const getDirArray = (dirString) => {
    if (!dirString) {
        return '';
    }
    let file_path_arr = [];
    if (dirString.includes('/')) {
        file_path_arr = dirString.split('/');
    } else {
        file_path_arr.push(dirString);
    }
    return file_path_arr;
}

export const getNavigateToDirData = (file, index, dirArray) => {
    if (!dirArray) {
        dirArray = getDirArray(file.path);
    }
    let path = '';
    let tmp_data = {};
    let data = {};
    if (Number(index) === 0) {
        if (dirArray === '') {
            return getNavigateBackData();
        } else {
            if (file.list[dirArray[index]]) {
                data = file.list[dirArray[index]];
                path = dirArray[index];
            }
        }
    } else {
        for (let i = 0; i <= index; i++) {
            let curr_path = dirArray[i];
            path += curr_path + '/';
            if (Number(i) === 0) {
                if (file.list[curr_path]) {
                    tmp_data = file.list[curr_path];
                }
            } else if (Number(i) === Number(index)) {
                if (tmp_data['dir'][curr_path]) {
                    data = tmp_data['dir'][curr_path]
                }
            } else {
                if (tmp_data['dir'][curr_path]) {
                    tmp_data = tmp_data['dir'][curr_path];
                }
            }
        }
    }
    return {
        type: 'dir',
        data,
        path,
    };
}

export const containsSpecialCharacter = (str) => {
    return config.specialCharacters.some(char => str.includes(char));
}

export const formatDateTime = (datetime, type) => {
    const date = datetime.split(" ")[0];
    const time = datetime.split(" ")[1];
    const reverse_date = date.split("-").reverse().join('-');
    const sliced_time = time.substring(0,5);

    switch(type) {
        case 'date':
            return reverse_date;
        case 'datetime':
            return reverse_date + ' ' + sliced_time;
        default:
    }
}

export const sortObjAlpha = (obj, sortKey) => {
    return Object.keys(obj).sort((a, b) => {
        let nameA = obj[a][sortKey].toLowerCase();
        let nameB = obj[b][sortKey].toLowerCase();

        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    });
}

document.addEventListener("DOMContentLoaded", function () {
    window.addEventListener("scroll", function () {
        var element = document.querySelector(".slide-in-element");
        var position = element.getBoundingClientRect().top;
        var screenHeight = window.innerHeight;

        if (position < screenHeight) {
            element.classList.add("slide-in");
        }
    });
});