import UserReducer from "./UserReducer";
import FileReducer from "./FileReducer";
import ModalReducer from "./ModalReducer";

const rootReducer = (state = {}, action) => ({
    user : UserReducer(state.user, action),
    file : FileReducer(state.file, action),
    modal: ModalReducer(state.modal, action),
})

export default rootReducer;