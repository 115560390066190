import Input from "./Input";

const Form = (props) => {
    const onSubmit = (e) => {
        e.preventDefault();
        props.onSubmit();
    }

    return (
        <form onSubmit={onSubmit}>
            {props.children}
            <Input
                style={props.hide ? {display: 'none'} : {}}
                type={'submit'}
                value={props.submitValue || 'Speichern'}
            />
        </form>
    )
}

export default Form;